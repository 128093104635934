import { gql } from 'apollo-angular';

export const SAVE_PREFERENCES_MUTATION = gql`
  mutation savePreferences($preferenceRequest: Input_SavePreferenceRequest) {
    preferences(preferenceRequest: $preferenceRequest) {
      isSuccess
      message
      errorCode
    }
  }
`;
