import { gql } from 'apollo-angular';

export const GET_PREFERENCE_QUERY = gql`
  query GetPreferences(
    $contactId: String
    $objectType: String
    $objectName: String
    $pageName: String
  ) {
    preferences(
      contactId: $contactId
      objectType: $objectType
      objectName: $objectName
      pageName: $pageName
    ) {
      isSuccess
      data {
        contactId
        pageName
        objectType
        objectName
        preferenceDetail
      }
    }
  }
`;
